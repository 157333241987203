import {
  QUESTION,
  SCREEN,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-variants';
import {
  BINARY,
  DEFAULT_QUESTION,
  INPUT,
  RADIO_LIST,
  ZIP,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-types';
import { InputWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/input-wizard-question';
import { ZipInputWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/zip-input-wizard-question';
import { RadioWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/radio-wizard-question';
import { BinaryWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/binary-wizard-question';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';
import {
  CHECKING_ELIGIBILITY,
  DEFAULT_SCREEN,
  ELIGIBILITY,
  INFORMATIVE,
  RESULT,
  START,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-types';
import { StartScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/start-screen';
import { BaseScreenStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-screen-step';
import { EligibilityScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/eligibility-screen';
import { ResultScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/result-screen';
import { CheckingEligibilityScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/checking-eligibility-screen';

export const STEP_FACTORY_COMPONENTS_MAP = {
  [QUESTION]: {
    [INPUT]: InputWizardQuestion,
    [ZIP]: ZipInputWizardQuestion,
    [RADIO_LIST]: RadioWizardQuestion,
    [BINARY]: BinaryWizardQuestion,
    [DEFAULT_QUESTION]: BaseWizardStep,
  },
  [SCREEN]: {
    [START]: StartScreen,
    [INFORMATIVE]: BaseScreenStep,
    [ELIGIBILITY]: EligibilityScreen,
    [RESULT]: ResultScreen,
    [CHECKING_ELIGIBILITY]: CheckingEligibilityScreen,
    [DEFAULT_SCREEN]: BaseWizardStep,
  },
};
