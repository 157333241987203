import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorEntities, VisitorModel } from 'client/data/models/visitor';
import { EVENTS } from 'client/utils/event-constants';
import { Zip } from 'site-modules/shared/components/zip/zip';

function ZipWithDefaultActionHandlingUI({
  location,
  buttonClassName,
  buttonText,
  showRegion,
  useCityStateText,
  showDropdownIcon,
  buttonTrackingId,
  setModelValue,
  onChange,
}) {
  const onSetZipCode = useCallback(
    newZipCode => {
      setModelValue('location', VisitorModel, { zipCode: newZipCode });
      EventToolbox.fireCustomEvent(EVENTS.UPDATE_ZIP, { zipCode: newZipCode });
      onChange(newZipCode);
    },
    [onChange, setModelValue]
  );

  if (!location) {
    return null;
  }

  const cityStateText = `${location.city}, ${location.stateCode}`;

  return (
    <Fragment>
      {showRegion && <span>{cityStateText}</span>}
      <Zip
        zip={location.zipCode}
        onUpdate={onSetZipCode}
        buttonClassName={buttonClassName}
        buttonText={useCityStateText ? `${cityStateText}` : buttonText}
        buttonTrackingId={buttonTrackingId}
        showDropdownIcon={showDropdownIcon}
      />
    </Fragment>
  );
}

ZipWithDefaultActionHandlingUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  location: VisitorEntities.Location,
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  showRegion: PropTypes.bool,
  useCityStateText: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  buttonTrackingId: PropTypes.string,
};

ZipWithDefaultActionHandlingUI.defaultProps = {
  location: null,
  onChange: noop,
  buttonClassName: '',
  buttonText: undefined,
  showRegion: false,
  useCityStateText: false,
  showDropdownIcon: undefined,
  buttonTrackingId: undefined,
};

export const propsAreEqual = (prevProps, { location }) =>
  get(prevProps, 'location.zipCode') === get(location, 'zipCode');
export const ZipWithDefaultActionHandling = connectToModel(React.memo(ZipWithDefaultActionHandlingUI, propsAreEqual), {
  location: bindToPath('location', VisitorModel),
});
