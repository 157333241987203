import React, { memo, useCallback, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { getStartScreenBulletsConfig } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-bullets-config';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getPotentialRebatesSum } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-potential-rebates-sum';
import { TrackingConstant } from 'client/tracking/constant';
import { Button } from 'site-modules/shared/components/inventory/button/button';
import {
  StepProps,
  StepPropsDefaults,
} from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { BulletsList } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/bullet-list';
import { WizardImage } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/wizard-image';
import { WizardDisclaimer } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/wizard-disclaimer';

import './start-screen.scss';

export const CLICK_BTN_COPY = 'Get Started';

export const StartScreen = memo(props => {
  const {
    stepConfig: {
      title,
      hideImage,
      btnCopy = CLICK_BTN_COPY,
      className = 'pt-3',
      btnClassName = 'py-1 w-100',
      bulletsTitle = 'We’ll check your eligibility for:',
      getBulletsConfig = getStartScreenBulletsConfig,
      isInternational,
    },
    context,
    allIncentives,
    onAnswer,
    creativeId,
  } = props;
  const totalRebates = getPotentialRebatesSum(allIncentives);
  const bulletsConfig = getBulletsConfig({ allIncentives, context });

  useEffect(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
      event_data: {
        action_name: TrackingConstant.ACTION_FILL_FORM,
        subaction_name: TrackingConstant.DISPLAY_INTRO,
        action_cause: TrackingConstant.ACTION_CAUSE_DRAWER_OPEN,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: creativeId,
        value: totalRebates,
      },
    });
  }, [creativeId, totalRebates]);

  const handleClick = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_FILL_FORM,
        subaction_name: TrackingConstant.START_INCENTIVES_MATCHER,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: creativeId,
        value: btnCopy,
      },
    });
    onAnswer();
  }, [creativeId, btnCopy, onAnswer]);

  return (
    <div className={classnames('start-screen pos-r d-flex flex-column justify-content-between', className)}>
      {!hideImage && <WizardImage bodyType={context.bodyType} />}
      {!!title && <h2 className="mb-1_5 text-center size-24 fw-bold">{title(context)}</h2>}
      <div className="mb-1_5 px-1_5 py-1 rounded-12 bg-blue-100 fw-bold d-flex align-items-center justify-content-between">
        <span>You could save</span>
        <span className="total-rebates-value size-24">
          {isInternational ? '$????' : formatPriceString(totalRebates)}
        </span>
      </div>
      {!isInternational && (
        <Fragment>
          <div className="mb-1">{bulletsTitle}</div>
          <BulletsList config={bulletsConfig} className="mb-0_5" />
        </Fragment>
      )}
      <Button
        color="blue-50"
        size="lg"
        onClick={handleClick}
        className={classnames('text-white text-transform-none fw-medium', btnClassName)}
      >
        {btnCopy}
      </Button>
      <WizardDisclaimer />
    </div>
  );
});

StartScreen.displayName = 'StartScreen';

StartScreen.propTypes = StepProps;
StartScreen.defaultProps = StepPropsDefaults;
